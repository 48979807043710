





























import { loginTenantId, TenantId } from "@/auth/auth.utils";
import { authService } from "@/auth/authService";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheLoginPage",
  setup() {
    //  TODO: Fix issue with signing in to different tenants. The MSAL setup happens when the application mounts
    //  changing between tenants does not work ideal
    const login = (tenantId: string) => {
      localStorage.setItem(loginTenantId, tenantId);
      authService.signIn();
    };
    const loginAofNorge = () => login(TenantId.aofNorge);
    const loginAofHaugland = () => login(TenantId.aofHaugland);

    return { loginAofNorge, loginAofHaugland };
  },
});
